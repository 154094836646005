<template>
     <v-app-bar app height="90" elevation="0"  color="primary" class="rounded-br-xl" >
         <v-app-bar-nav-icon @click="$router.push('/')" v-if="backward">
       <template slot="default">
           <v-btn icon color="white" :to="{name: 'savedraft'}" ><v-icon>mdi-arrow-left</v-icon></v-btn>
       </template>
         </v-app-bar-nav-icon>
         <v-list two-line dense color="transparent">
             <v-list-item>
                    <v-list-item-avatar size="52" color="white" style="border-style: solid" >  <v-img 
                      :src="imageProfile || require('@/assets/AvatarDefault.png')"
                      @error="imageProfile = null"
                    ></v-img></v-list-item-avatar>
                 <v-list-item-content>
                  
                     <v-list-item-title class="text-title-1 natural--text" v-text="role.fullname">Pattarachon</v-list-item-title>
                      <v-list-item-subtitle class="text-title-3 natural--text" v-text="role.roleName">เจ้าหน้าที่</v-list-item-subtitle>
                 </v-list-item-content>
             </v-list-item>
         </v-list>
     </v-app-bar>
</template>

<script>
import {getProfilePicture} from "@/api"
import { offlineimage } from "@/mixins/offlineimage";

export default {
  props: {
    title: {
      type: String,
      default: 'หัวข้อแสดงผล H4' 
    },
    backward: {
        type: Boolean,
        default: false
    }
  },
    mixins: [offlineimage],
  data() {
    return {
      role: null,
      imageProfile: null
    }
  },
  methods: {
    fetchData() {
       this.role = this.$offlineStorage.get('role')
       const site = this.$offlineStorage.get("site");
       const tenant = this.$offlineStorage.get("tenant");
      if (navigator.onLine ){
      this.loading = true
      getProfilePicture({tenantId: tenant.tenantId ,siteId:site.siteId,profilePicture: this.role.profilePicture},message=>{
        if(message.data.code===1){
            this.imageProfile = message.data.result
            this.saveImage(message.data.result, this.role.profilePicture);
        }
        this.loading=false
      },error=>{console.log(error)})
      }else{
          this.imageProfile = this.loadImage(this.role.profilePicture)
      }
    }
  },
   created () {
     this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  }

}
</script>

<style lang="scss" scoped>
    .v-application .rounded-br-xl {
    border-bottom-right-radius: 24px !important;
  }

</style>